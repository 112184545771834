.App {
  text-align: center;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.logo-container {
  box-sizing: border-box;
  min-width: 0px;
  width: 2rem;
  height: 2rem;
  margin: 1rem 2rem;
  position: relative;
  display: block;
}

.logo {
  height: 2rem !important;
}

.header-bar {
  background-color: rgb(23, 43, 77);
  min-height: 5vh;
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  top: 0rem;
  display: flex;
  height: 4rem;
  padding: 0px;
  background: rgb(23, 43, 77);
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  z-index: 3;
  position: sticky;
}

.header-bar-right {
  box-sizing: border-box;
  margin: 0px 1rem 0px 0px;
  min-width: 0px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.header-bar-right-item {
  box-sizing: border-box;
  margin: 0px 0.5rem 0px 0px;
  min-width: 0px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.header-bar-right-item a {
  text-decoration: none;
  color: rgb(201, 216, 239);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
