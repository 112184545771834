.toolFeature span {
    font-weight: bold;
}
.toolFeature {
    margin-right: 10px;
}

.toolList {
    width: 20%;
    height: 70rem;
}

.toolDetails {
    width: 80%;
    height: 70rem;
    overflow: scroll;
}

.codePatternsList {
    height: 50rem;
    overflow: scroll;
}

.next {
    margin-left: 30px;
}